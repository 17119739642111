// Documents.js
import React, { useEffect, useState, useRef } from "react";
import { View, Image, ScrollView, Text, StyleSheet, TextInput, TouchableOpacity, Modal, Platform } from "react-native";
import { Button, Card, List } from "react-native-paper";
import { useRoute } from "@react-navigation/native";
import { firestore } from "../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, getDocs, getDoc, query, where, doc, updateDoc } from "firebase/firestore";
import SignatureCanvas from "./SignatureCanvas"; // Adjust the import path as necessary
import { PDFDocument, fetchAsset } from "pdf-lib";
import StartPage from "../src/StartPage"; // Import StartPage
import { useLocation } from "@react-navigation/native";

const Documents = ({ route, navigation }) => {
  const { companyName, projectId } = route.params ?? {};
  const [documents, setDocuments] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [projectDoc, setProjectDoc] = useState(null);
  const [changeRequest, setChangeRequest] = useState("");
  const [dateAndCity, setDateAndCity] = useState("");
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);
  const [signatureData, setSignatureData] = useState(null);
  const [selectedDocPdfUrl, setSelectedDocPdfUrl] = useState(null);
  const [inputHeight, setInputHeight] = useState(20); // Initial height
  const isMobile = window.innerWidth <= 768; // Example breakpoint for mobile devices
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  if (!companyName || !projectId) {
    return <StartPage />;
  }

  useEffect(() => {
    const fetchUploadedFiles = async () => {
      const docRef = doc(firestore, `companies/${companyName}/projects/${projectId}`);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        setProjectDoc(docSnap);
        const folders = docSnap.data().folders || {};
        const folder = folders["9999"];
        if (folder && folder.imageUrls) {
          setUploadedFiles(folder.imageUrls);
        }
      }
    };
  
    fetchUploadedFiles();
  }, [companyName, projectId]);

  const fetchDocuments = async () => {
    try {
      const docsCollection = collection(firestore, `companies/${companyName}/documents`);
      const q = query(
        docsCollection,
        where("projectId", "==", projectId),
        where("doctype", "in", ["Angebot", "Vollmacht"])
      );
      const docSnap = await getDocs(q);
      setDocuments(docSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };
  
  // Fetch documents initially and whenever companyName or projectId changes
  useEffect(() => {
    fetchDocuments();
  }, [companyName, projectId]);

  const showSignatureModal = (docId) => {
    setSelectedDocId(docId);
    setSignatureModalVisible(true);
  };

  const handleRequestChange = (docId) => {
    console.log("Requesting change for document:", docId, changeRequest);
    const docRef = doc(firestore, `companies/${companyName}/documents`, docId);
    updateDoc(docRef, { changeRequest: changeRequest });
    setModalVisible(false);
  };

  const handleSignatureEnd = async (docId) => {
    if (!dateAndCity) {
      alert("Bitte Datum und Ort eingeben");
      return;
    }

    const firestoreDocRef = doc(firestore, `companies/${companyName}/documents`, docId);

    if (signatureData.startsWith("data:image")) {
      try {
        await updateDoc(firestoreDocRef, { signatureData: signatureData, signatureDateAndCity: dateAndCity });
        const response = await fetch(`https://sunvault-backend-v2-e356aa9c1d84.herokuapp.com/tasks/0/${companyName}/${projectId}/${docId}/0/signPdf`);
        console.log(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        } else {
          console.log("response is OK");
        }
      } catch (error) {
        console.error("Error updating document with signature data:", error);
      }
    } else if (signatureData.startsWith("http")) {
      try {
        await updateDoc(firestoreDocRef, { signedPdf: signatureData });
      } catch (error) {
        console.error("Error updating document with uploaded image URL:", error);
      }
    }
    setSignatureModalVisible(false);
    await fetchDocuments();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file); // Update the selected file state
    }
  };

  const uploadFile = async () => {
    if (!selectedFile) return;
    const currentData = projectDoc.data();
    let folders = currentData.folders || {};
    const storagePath = `${companyName}/projects/${projectId}/folders/1/${selectedFile.name}`;
    const fileRef = ref(getStorage(), storagePath);
    try {
      // Upload file to Firebase Storage
      const snapshot = await uploadBytes(fileRef, selectedFile);
      const downloadURL = await getDownloadURL(snapshot.ref);
  
      // Update the folders object with the new file's download URL
      let folder = folders["9999"] || {};
      folder.imageUrls = [...(folder.imageUrls || []), downloadURL];
      folders["9999"] = folder;
  
      // Update Firestore document with the updated folders
      const docRef = doc(firestore, `companies/${companyName}/projects/${projectId}`);
      await updateDoc(docRef, { folders });
      console.log("File uploaded and Firestore updated!");
  
      // Update local state to display the uploaded file
      setUploadedFiles((prevFiles) => [...prevFiles, downloadURL]);
      setSelectedFile(null); // Reset selected file
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  

  const styles = StyleSheet.create({
    scrollViewContainer: {
      flexGrow: 0, // Prevent ScrollView from taking up more space than its content
    },
    imageContainer: {
      marginRight: 10, // Adds space between images
    },
    image: {
      width: 100, // Set your desired width
      height: 100, // Set your desired height
      borderRadius: 5, // Optional: if you want rounded corners
    },
    container2: {
      flexDirection: "row",
      alignItems: "center",
      padding: 10,
    },
    container: {
      padding: 20,
      backgroundColor: "#1E1E1E",
      height: "100%",
    },
    header: {
      fontSize: 15,
      fontWeight: "semi-bold",
      color: "#fff",
      marginBottom: 10,
      marginTop: 20,
      alignSelf: "center",
    },
    buttonDisabled: {
      backgroundColor: "#cccccc", // Grey color
      // Other styling to indicate the button is disabled, if necessary
    },
    card: {
      backgroundColor: "#3e3e42",
      borderRadius: 8,
      padding: 16,
      marginBottom: 20,
      shadowOpacity: 0.2,
      shadowRadius: 10,
      shadowColor: "#000",
      shadowOffset: { height: 5, width: 0 },
    },
    cardRow: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      fontSize: isMobile ? 12 : 18,
      fontWeight: "light",
      color: "#fff",
    },
    buttonText: {
      fontSize: 12,
      fontWeight: "light",
      color: "#fff",
    },
    uploadButton: {
      display: "inline-block",
      padding: "10px",
      color: "#fff",
      backgroundColor: "#444",
      borderRadius: "5px",
      cursor: "pointer",
      marginTop: "10px",
    },
    warning: {
      fontSize: isMobile ? 10 : 15,
      color: "red",
    },
    good: {
      fontSize: isMobile ? 10 : 15,
      color: "green",
    },
    modalView: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
    },

    iframe: {
      width: "80%",
      height: "80%",
      border: "none",
      marginBottom: 10,
    },
    input: {
      margin: 12,
      height: 100,
      borderWidth: 0.5,
      borderColor: "white",
      borderRadius: 10,
      padding: 10,
      width: "80%",
      color: "white",
    },
    buttonsContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      marginLeft: 10,
      paddingHorizontal: 10,
      paddingVertical: 5,
      backgroundColor: "#bb86fc",
      borderRadius: 5,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
  });

  return (
    <View style={styles.container}>
      <Text style={styles.header}>Ihre Dokumente warten auf Freigabe</Text>

      {documents.map((doc) => (
        <View key={doc.id} style={styles.card}>
          <View style={styles.cardRow}>
            <Text style={styles.title}>
              {doc.doctype}-{doc.docNr}{" "}
            </Text>
            {doc.changeRequest && (
              <>
                <Text style={styles.good}>✏️Änderungen</Text>
              </>
            )}
            {doc.signedPdf && (
              <>
                <Text style={styles.good}>✔ signiert</Text>
              </>
            )}
            {!doc.signedPdf && (
              <>
                <View style={styles.buttonsContainer}>
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedDocPdfUrl(doc.pdf);
                      showSignatureModal(doc.id);
                    }}
                    style={styles.button}>
                    <Text style={styles.buttonText}>✍️ signieren</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      setSelectedDocId(doc.id);
                      setSelectedDocPdfUrl(doc.pdf);
                      setModalVisible(true);
                    }}
                    style={styles.button}>
                    <Text style={styles.buttonText}>📝 Änderung</Text>
                  </TouchableOpacity>
                </View>
              </>
            )}
          </View>
        </View>
      ))}
      <Text style={styles.header}>Hier können Sie Bilder hochladen</Text>

      <Card style={styles.card}>
        <Card.Content>
          <Button icon="upload" mode="contained" onPress={() => document.getElementById("fileInput").click()}>
            Bild auswählen
          </Button>
          <input
            id="fileInput"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }} // Hide the input element
          />
          {selectedFile && (
            <>
              <Button
                icon="file-upload"
                mode="contained"
                disabled={!selectedFile}
                onPress={uploadFile}
                contentStyle={{ backgroundColor: "#3E3E42" }}
                style={styles.uploadButton}>
                {selectedFile ? `${selectedFile.name.slice(0, 10)}${selectedFile.name.length > 10 ? "..." : ""}` : "Datei"}{" "}
                hochladen
              </Button>
            </>
          )}
        </Card.Content>
      </Card>
      <View style={styles.scrollViewContainer}>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} contentContainerStyle={styles.container2}>
          {uploadedFiles.map((url, index) => (
            <View key={index} style={styles.imageContainer}>
              <Image source={{ uri: url }} style={styles.image} />
            </View>
          ))}
        </ScrollView>
      </View>

      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}>
        <View style={styles.modalView}>
          <TouchableOpacity onPress={() => setModalVisible(false)} style={styles.closeButton}>
            <Text style={styles.buttonText}>X</Text>
          </TouchableOpacity>
          {selectedDocPdfUrl && <iframe src={selectedDocPdfUrl} style={styles.iframe}></iframe>}
          <TextInput style={styles.input} onChangeText={setChangeRequest} value={changeRequest} placeholder="Änderungen eingeben" multiline />
          <TouchableOpacity onPress={() => handleRequestChange(selectedDocId)} style={styles.button}>
            <Text style={styles.buttonText}>Änderungen senden</Text>
          </TouchableOpacity>
        </View>
      </Modal>

      <Modal
        visible={signatureModalVisible}
        transparent={true}
        animationType="fade"
        onRequestClose={() => {
          setSignatureModalVisible(!signatureModalVisible);
        }}>
        <View style={styles.modalView}>
          <TouchableOpacity onPress={() => setSignatureModalVisible(false)} style={styles.closeButton}>
            <Text style={styles.buttonText}>X</Text>
          </TouchableOpacity>
          {selectedDocPdfUrl && <iframe src={selectedDocPdfUrl} style={styles.iframe}></iframe>}
          <TextInput style={styles.input} onChangeText={setDateAndCity} value={dateAndCity} placeholder="Ort, Datum eingeben" />
          <SignatureCanvas onDrawingComplete={setSignatureData} />
          <TouchableOpacity
            onPress={() => handleSignatureEnd(selectedDocId)}
            style={[styles.button, !signatureData && styles.buttonDisabled]}
            disabled={!signatureData}>
            <Text style={styles.buttonText}>Absenden</Text>
          </TouchableOpacity>
        </View>
      </Modal>
    </View>
  );
};

export default Documents;
