// App.js
import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Documents from "./src/Documents";
import StartPage from "./src/StartPage"; // Import StartPage

const Stack = createStackNavigator();

const App = () => {
  const config = {
    screens: {
      Documents: {
        path: "documents",
        parse: {
          companyName: (companyName) => `${companyName}`,
          projectId: (projectId) => `${projectId}`,
        },
      },
      StartPage: "",
    },
  };

  const linking = {
    prefixes: ["http://localhost:19006/", "localhost:19006://", "https://solarpp-web.vercel.app", "solarpp-web.vercel.app"],
    config,
  };

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName="StartPage">
        <Stack.Screen name="StartPage" component={StartPage} options={{ headerShown: false }} />
        <Stack.Screen name="Documents" component={Documents} options={{ headerShown: false }} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default App;
