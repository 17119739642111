import React, { useRef, useEffect } from "react";

const SignatureCanvas = ({ onDrawingComplete }) => {
  const canvasRef = useRef(null);

  const getCoordinates = (event) => {
    const canvas = canvasRef.current;
    if (event.touches && event.touches.length > 0) {
      const touch = event.touches[0];
      const rect = canvas.getBoundingClientRect();
      return {
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top,
      };
    } else {
      const rect = canvas.getBoundingClientRect();
      return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    let drawing = false;

    const startDrawing = (e) => {
      drawing = true;
      const coordinates = getCoordinates(e);
      context.beginPath();
      context.moveTo(coordinates.x, coordinates.y);
    };

    const draw = (e) => {
      if (!drawing) return;
      e.preventDefault(); // Prevent scrolling when touching
      const coordinates = getCoordinates(e);
      context.lineTo(coordinates.x, coordinates.y);
      context.stroke();
      context.beginPath();
      context.moveTo(coordinates.x, coordinates.y);
    };

    const endDrawing = () => {
      if (drawing) {
        drawing = false;
        context.closePath();
        onDrawingComplete(canvas.toDataURL()); // Notify the main component
      }
    };

    // Add mouse event listeners
    canvas.addEventListener("mousedown", startDrawing);
    canvas.addEventListener("mousemove", draw);
    canvas.addEventListener("mouseup", endDrawing);
    canvas.addEventListener("mouseleave", endDrawing);

    // Add touch event listeners
    canvas.addEventListener("touchstart", startDrawing);
    canvas.addEventListener("touchmove", draw);
    canvas.addEventListener("touchend", endDrawing);

    return () => {
      // Remove mouse event listeners
      canvas.removeEventListener("mousedown", startDrawing);
      canvas.removeEventListener("mousemove", draw);
      canvas.removeEventListener("mouseup", endDrawing);
      canvas.removeEventListener("mouseleave", endDrawing);

      // Remove touch event listeners
      canvas.removeEventListener("touchstart", startDrawing);
      canvas.removeEventListener("touchmove", draw);
      canvas.removeEventListener("touchend", endDrawing);
    };
  }, [onDrawingComplete]);

  return (
    <>
      <div style={{ marginBottom: "10px", color: "white", fontSize: "18px", textAlign: "center" }}>Hier unterschreiben</div>
      <div style={{ position: "relative" }}>
        <canvas ref={canvasRef} width="350" height="100" style={{ border: "1px solid white", borderRadius: "10px", backgroundColor: "white" }} />
        <button
          onClick={() => {
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            context.clearRect(0, 0, canvas.width, canvas.height);
            onDrawingComplete(null);
          }}
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            background: "transparent",
            border: "none",
            cursor: "pointer",
            fontSize: "16px",
            color: "black",
          }}>
          X
        </button>
      </div>
    </>
  );
};

export default SignatureCanvas;
