// src/StartPage.js
import React from 'react';
import { View, Text, Button } from 'react-native';

const StartPage = ({ navigation }) => {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text>Welcome to the App</Text>
      <Button
        title="Go to Documents"
        onPress={() => navigation.navigate('Documents', { companyName: 'DefaultCompany', projectId: 'DefaultProject' })}
      />
    </View>
  );
};

export default StartPage;
