import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyDZZThI_QMOeaJklBVOmykTpDf0lAwb5WY",
  authDomain: "solardb-383d7.firebaseapp.com",
  projectId: "solardb-383d7",
  storageBucket: "solardb-383d7.appspot.com",
  messagingSenderId: "363668205529",
  appId: "1:363668205529:web:fba92acffa50183011b3c8",
  measurementId: "G-HSQ3PZ7RJF", 
  databaseURL:
  "https://solardb-383d7-default-rtdb.europe-west3.firebasedatabase.app",
};
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const firestore = getFirestore(app);